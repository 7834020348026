import "../styles/MentionsLegales.scss";

import Footer from "../components/Footer.jsx";
import Navbar from "../components/Navbar.jsx";
import React from "react";
import ReactMarkdown from "react-markdown";
import { graphql } from "gatsby";

function MentionsLegales({ data }) {
  const markdownFromContentful =
    data.allContentfulMentionsLegales.edges[0].node.mentionsLegales
      .mentionsLegales;

  return (
    <div className="mentionslegales-container">
      <Navbar currentPage="" />
      <div className="text-container">
        <div className="text-inner">
          <ReactMarkdown
            source={markdownFromContentful}
            className="reactMarkDown"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export const query = graphql`
  query MentionsLegalesQuery {
    allContentfulMentionsLegales {
      edges {
        node {
          mentionsLegales {
            mentionsLegales
          }
        }
      }
    }
  }
`;

export default MentionsLegales;
